"use client";

import { Platform } from "@/__generated__/graphql";
import { useAuthContext } from "@/context/AuthContext";
import { MixpanelEventName } from "@/types";
import type { ConfirmationResult } from "firebase/auth";
import {
  parsePhoneNumberFromString,
  type CountryCode,
} from "libphonenumber-js";
import { Dispatch, SetStateAction, useState } from "react";
import AuthInput from "./AuthInput";

interface Props {
  phoneNumberResult: ConfirmationResult | null;
  setPhoneNumberResult: Dispatch<SetStateAction<ConfirmationResult | null>>;
}

/**
 * Validates phone number by checking if it has a minimum number of digits.
 * @param phoneNumber Phone number to validate
 * @returns Boolean indicating whether it has enough digits to be valid
 */
function isValidPhoneNumber(phoneNumber: string): boolean {
  if (!phoneNumber.trim()) return false;

  // Count only digits, ignoring formatting characters
  const digitCount = phoneNumber.replace(/\D/g, "").length;

  // Most countries have at least 7 digits in phone numbers
  // This is a simplified approach that will enable the button
  // once there's a reasonable number of digits
  return digitCount >= 7;
}

export default function AuthContainer({
  phoneNumberResult,
  setPhoneNumberResult,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [countryCode, setCountryCode] = useState<CountryCode>("US");
  const { signInWithPhoneNumber, verifyPhoneNumberWithVerificationCode } =
    useAuthContext();

  async function signIn() {
    try {
      setIsLoading(true);
      console.log("Original phone number input:", phoneNumber);

      // Parse the phone number to get a properly formatted E.164 version
      const parsedNumber = parsePhoneNumberFromString(phoneNumber, countryCode);

      // If we can parse it, use the E.164 format (e.g., +12125551234)
      // Otherwise fall back to adding the country code manually
      const formattedNumber =
        parsedNumber?.format("E.164") ||
        (phoneNumber.startsWith("+")
          ? phoneNumber
          : `+1${phoneNumber.replace(/\D/g, "")}`);

      console.log("Sending formatted number to Firebase:", formattedNumber);
      console.log("Parsed number object:", parsedNumber);

      const result = await signInWithPhoneNumber(formattedNumber);
      console.log("result", result);
      setPhoneNumberResult(result);
    } catch (error) {
      console.error("Error in signIn:", error);
      // TODO: add user-friendly error handling here
      throw error;
    } finally {
      setIsLoading(false);
    }
  }

  async function verifyCode() {
    if (!phoneNumberResult) return;

    try {
      setIsLoading(true);
      await verifyPhoneNumberWithVerificationCode(
        phoneNumberResult,
        verificationCode,
        Platform.MarketplaceWeb
      );
    } catch (error) {
      console.error("Error in verifiyCode:", error);
      // TODO: add user-friendly error handling here
      throw error;
    } finally {
      setIsLoading(false);
    }
  }

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    // Special handling for US phone numbers
    if (countryCode === "US") {
      // Extract just the digits from the input, ignoring all formatting
      const digits = value.replace(/\D/g, "");

      // Limit to 10 digits max (US numbers)
      const limitedDigits = digits.slice(0, 10);

      // Format the digits according to US format
      let formattedValue = "";
      if (limitedDigits.length === 0) {
        formattedValue = "";
      } else if (limitedDigits.length <= 3) {
        formattedValue = limitedDigits;
      } else if (limitedDigits.length <= 6) {
        formattedValue = `(${limitedDigits.slice(0, 3)}) ${limitedDigits.slice(3)}`;
      } else {
        formattedValue = `(${limitedDigits.slice(0, 3)}) ${limitedDigits.slice(3, 6)}-${limitedDigits.slice(6)}`;
      }

      // Set the formatted value
      setPhoneNumber(formattedValue);
      return;
    }

    // For non-US phone numbers, keep the existing logic
    // Filter to only keep digits, +, spaces, parentheses, and hyphens
    const filtered = value.replace(/[^\d\s+()-]/g, "");

    // Ensure we don't exceed 15 digits (E.164 standard maximum)
    const digitCount = filtered.replace(/\D/g, "").length;
    if (digitCount > 15) {
      return; // Don't process if already at max length
    }

    try {
      const phoneNumber = parsePhoneNumberFromString(filtered, countryCode);

      if (phoneNumber) {
        // Format according to national conventions
        setPhoneNumber(phoneNumber.formatNational());
      } else {
        // If it can't be parsed yet, just use the filtered input
        setPhoneNumber(filtered);
      }
    } catch (error) {
      // If parsing fails, use the filtered input
      setPhoneNumber(filtered);
    }
  };

  // Create a setValue function that conforms to the expected type
  const setPhoneNumberFormatted = (
    value: string | ((prevState: string) => string)
  ) => {
    // If it's a function, we need to execute it to get the new value
    const newValue = typeof value === "function" ? value(phoneNumber) : value;

    // Create a synthetic event to reuse our existing handler
    const syntheticEvent = {
      target: { value: newValue },
    } as React.ChangeEvent<HTMLInputElement>;

    handlePhoneChange(syntheticEvent);
  };

  if (phoneNumberResult) {
    return (
      <AuthInput
        value={verificationCode}
        setValue={setVerificationCode}
        onSubmit={verifyCode}
        eventName={
          MixpanelEventName.LANDING_PAGE_COMING_SOON_VERIFICATION_CODE_SUBMIT
        }
        eventProperties={{ phoneNumber, countryCode }}
        placeholder="ENTER CODE"
        inputType="text"
        countryCode={countryCode}
        setCountryCode={setCountryCode}
        isLoading={isLoading}
        disabled={
          // Verification code has to be 6 digits long and contain only numbers
          verificationCode.length !== 6 || !/^\d+$/.test(verificationCode)
        }
      />
    );
  }

  return (
    <AuthInput
      value={phoneNumber}
      setValue={setPhoneNumberFormatted}
      onSubmit={signIn}
      eventName={MixpanelEventName.LANDING_PAGE_COMING_SOON_PHONE_NUMBER_SUBMIT}
      eventProperties={{ phoneNumber, countryCode }}
      placeholder="PHONE NUMBER"
      inputType="tel"
      countryCode={countryCode}
      setCountryCode={setCountryCode}
      isLoading={isLoading}
      disabled={!isValidPhoneNumber(phoneNumber)}
    />
  );
}
