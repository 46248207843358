"use client";

import Body from "./Body";

export default function Page() {
  return (
    <div className="relative flex h-dvh w-full flex-col bg-white p-10">
      <Body />
    </div>
  );
}
