"use client";

import Button from "@/components/shared/Button";
import type { LogEventProperties, MixpanelEventName } from "@/types";
import { Input } from "@phiaco/phia-ui";
import { DropdownOption } from "@phiaco/phia-ui/src/components/Dropdown/Dropdown";
import { type CountryCode } from "libphonenumber-js";
import type { Dispatch, HTMLInputTypeAttribute, SetStateAction } from "react";
import { CountryCodes } from "validator/lib/isISO31661Alpha2";

interface Props {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  onSubmit: () => Promise<void>;
  eventName: MixpanelEventName;
  eventProperties: LogEventProperties;
  placeholder: string;
  inputType: HTMLInputTypeAttribute;
  countryCode: CountryCode;
  setCountryCode: Dispatch<SetStateAction<CountryCode>>;
  isLoading: boolean;
  disabled?: boolean;
}

function getFlagEmoji(countryCode: CountryCode) {
  const codePoints = countryCode
    .toUpperCase()
    .split("")
    .map(char => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
}

const COUNTRY_CODES: DropdownOption[] = Array.from(CountryCodes).map(code => ({
  label: `${getFlagEmoji(code as CountryCode)} ${code}`,
  value: code,
}));

export default function AuthInput({
  value,
  setValue,
  onSubmit,
  eventName,
  eventProperties,
  placeholder,
  inputType,
  countryCode,
  setCountryCode,
  disabled = false,
  isLoading = false,
}: Props) {
  const submitButton = (
    <Button
      eventName={eventName}
      eventProperties={eventProperties}
      disabled={disabled}
      onClick={onSubmit}
      isLoading={isLoading}
      /** TODO: update colors to ensure consistency between different states (neutral, loading, disabled)  */
      className="md:text-md disabled:bg-overlay-300 w-full rounded-xs bg-bg-accent px-2 py-0.5 font-sans text-sm font-bold text-cn-onColor outline-none disabled:bg-bg-tertiary disabled:text-overlay-inverse200 md:w-auto md:rounded-sm md:px-3 md:py-1"
      innerClassName="label-md py-2 !bg-transparent !text-inherit sm:label-lg">
      Submit
    </Button>
  );

  return (
    <div className="flex w-max flex-col gap-2">
      <div className="flex flex-col rounded-md border border-gray-300 bg-white/40 py-3 pl-4 pr-2 backdrop-blur-md md:rounded-lg">
        <div className="flex flex-row gap-3">
          <select
            name="countries"
            id="country-code-select"
            value={countryCode}
            onChange={event =>
              setCountryCode(event.target.value as CountryCode)
            }
            style={{
              background:
                "url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 95% 50%",
              MozAppearance: "none",
              WebkitAppearance: "none",
              appearance: "none",
              width: "100px",
            }}>
            {COUNTRY_CODES.map(({ label, value }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
          <Input
            containerClassName="w-full max-w-[350px] py-2"
            className="text-md w-full flex-1 border-transparent bg-transparent p-0 font-mono outline-none placeholder:font-mono focus:!border-transparent md:pr-16 md:text-base"
            placeholder={placeholder}
            type={inputType}
            value={value}
            onChange={event => setValue(event.target.value)}
            rightSlot={<div className="hidden md:block">{submitButton}</div>}
          />
        </div>
        <div className="mt-3 w-full md:hidden">{submitButton}</div>
      </div>
    </div>
  );
}
