"use client";

import "./styles.css";
import { useAuthContext } from "@/context/AuthContext";
import { URLS } from "@/utils/urls";
import { ConfirmationResult } from "firebase/auth";
import { motion } from "framer-motion";
import Image from "next/image";
import { memo, useEffect, useMemo, useState } from "react";
import Animation from "./Animation";
import AuthContainer from "./AuthContainer";
import InstagramLogo from "./Icons/InstagramLogo";
import LinkedInLogo from "./Icons/LinkedInLogo";
import TiktokLogo from "./Icons/TiktokLogo";

const RECAPTCHA_CONTAINER_ID = "recaptcha-container";

function Body() {
  const { isLoggedIn: rawIsLoggedIn, addRecaptchaVerifier } = useAuthContext();
  const [phoneNumberResult, setPhoneNumberResult] =
    useState<ConfirmationResult | null>(null);
  const [animationCompleted, setAnimationCompleted] = useState(false);

  const isLoggedIn = useMemo(() => rawIsLoggedIn, [rawIsLoggedIn]);
  console.log("isloggedin", isLoggedIn);

  useEffect(() => {
    if (!animationCompleted) return;
    try {
      addRecaptchaVerifier(RECAPTCHA_CONTAINER_ID);
    } catch (error) {
      console.error("Error adding captcha:", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animationCompleted]);

  return (
    <>
      <div className="pointer-events-none absolute inset-0 overflow-hidden opacity-100">
        <Animation setCompleted={setAnimationCompleted} />
      </div>

      {animationCompleted ? (
        <>
          {/* Main content */}
          <div className="flex flex-1 flex-col items-center justify-center">
            {/* Bag image with overlapping text */}
            <div className="relative mb-8">
              <motion.div
                className="main-text absolute inset-0 z-20 flex flex-col items-center justify-center space-y-8 md:space-y-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.8, delay: 0.2 }}>
                <div className="flex flex-col items-center justify-center space-y-4 md:space-y-8">
                  <Image
                    src="/coming-soon/phiaBlack.avif"
                    alt="Phia"
                    width={62}
                    height={34}
                    className="h-[34px]"
                  />
                  <div className="-space-y-4 text-center">
                    <h1 className="whitespace-nowrap font-sans text-[44px] leading-tight mix-blend-overlay md:text-[70px]">
                      A new way
                    </h1>
                    <h1 className="whitespace-nowrap font-sans text-[44px] leading-tight text-black mix-blend-overlay md:text-[70px]">
                      to shop online.
                    </h1>
                  </div>
                </div>
                <div className="space-y-5">
                  <div className="space-y-0.5 text-center">
                    <p className="whitespace-nowrap text-center font-mono text-sm md:text-base">
                      {isLoggedIn
                        ? "YOU'RE ON THE WAITLIST."
                        : phoneNumberResult
                          ? "ENTER VERIFICATION CODE"
                          : "SIGN UP FOR EARLY ACCESS."}
                    </p>
                  </div>
                  {!isLoggedIn && (
                    <AuthContainer
                      phoneNumberResult={phoneNumberResult}
                      setPhoneNumberResult={setPhoneNumberResult}
                    />
                  )}
                </div>
              </motion.div>
            </div>
          </div>

          {/* Recaptcha container - only show when phone number result is null */}
          {!isLoggedIn && (
            <div
              id={RECAPTCHA_CONTAINER_ID}
              className="absolute bottom-4 left-4"></div>
          )}

          {/* Footer */}
          <footer className="mt-8 hidden items-center justify-between md:flex">
            <h5 className="font-xxs font-sans">© 2025 Phia Holdings Inc</h5>
            <div className="flex gap-4">
              <a
                className="opacity-70 transition-all duration-500 hover:opacity-100"
                href={URLS.INSTAGRAM}
                aria-label="Instagram">
                <InstagramLogo />
              </a>
              <a
                className="opacity-70 transition-all duration-500 hover:opacity-100"
                href={URLS.TIKTOK}
                aria-label="TikTok">
                <TiktokLogo />
              </a>
              <a
                className="opacity-70 transition-all duration-500 hover:opacity-100"
                href={URLS.LINKEDIN}
                aria-label="LinkedIn">
                <LinkedInLogo />
              </a>
            </div>
          </footer>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default memo(Body);
