"use client";

import { VERCEL_BLOB_URL } from "@/constants";
import { useTailwindBreakpoint } from "@phiaco/phia-ui";
import {
  animate,
  motion,
  MotionValue,
  useMotionValue,
  useTransform,
} from "framer-motion";
import { memo, useEffect, useState } from "react";

interface Props {
  setCompleted: (value: boolean) => void;
}

const BACKGROUND_CARDS = [
  {
    id: "cardOne",
    image: {
      src: `${VERCEL_BLOB_URL}/LandingPage2025/cardOne.avif`,
      width: 446,
      height: 336,
    },
  },
  {
    id: "cardTwo",
    image: {
      src: `${VERCEL_BLOB_URL}/LandingPage2025/cardTwo.avif`,
      width: 406,
      height: 304,
    },
  },
  {
    id: "cardThree",
    image: {
      src: `${VERCEL_BLOB_URL}/LandingPage2025/cardThree.avif`,
      width: 381,
      height: 296,
    },
  },
  {
    id: "cardFour",
    image: {
      src: `${VERCEL_BLOB_URL}/LandingPage2025/cardFour.avif`,
      width: 365,
      height: 284,
    },
  },
  {
    id: "cardFive",
    image: {
      src: `${VERCEL_BLOB_URL}/LandingPage2025/cardFive.avif`,
      width: 487,
      height: 365,
    },
  },
  {
    id: "cardSix",
    image: {
      src: `${VERCEL_BLOB_URL}/LandingPage2025/cardSix.avif`,
      width: 474,
      height: 370,
    },
  },
  {
    id: "cardSeven",
    image: {
      src: `${VERCEL_BLOB_URL}/LandingPage2025/cardSeven.avif`,
      width: 409,
      height: 308,
    },
  },
  {
    id: "cardEight",
    image: {
      src: `${VERCEL_BLOB_URL}/LandingPage2025/cardEight.avif`,
      width: 372,
      height: 280,
    },
  },
];

const MOBILE_LAYOUT = [
  { width: 240, height: 340, x: 120, y: -230 },
  { width: 400, height: 350, x: -85, y: 300 },
  { width: 247.65, height: 192.4, x: "200%", y: 0 },
  { width: 400, height: 325, x: 120, y: 300 },
  { width: 350, height: 250, x: -100, y: -248 },
  { width: 308.1, height: 240.5, x: -220, y: 0 },
  { width: 265.85, height: 200.2, x: 0, y: "-400%" },
  { width: 241.8, height: 182, x: "400%", y: -171.28 },
];

const SAFARI_LAYOUT = [
  { width: 200, height: 300, x: 110, y: -260 },
  { width: 400, height: 350, x: -90, y: 250 },
  { width: 247.65, height: 192.4, x: "150%", y: 0 },
  { width: 400, height: 325, x: 100, y: 250 },
  { width: 350, height: 250, x: -95, y: -270 },
  { width: 308.1, height: 240.5, x: -215, y: 5 },
  { width: 265.85, height: 200.2, x: 0, y: "-350%" },
  { width: 241.8, height: 182, x: "350%", y: -160 },
];

// Individual image component
interface AnimatedImageProps {
  src: string;
  baseAngle: number;
  phase: "group" | "scatter";
  randomPos: { x: number; y: number };
  progress: MotionValue<number>;
  index: number;
  width: number;
  height: number;
  onScatterComplete?: () => void;
}

function AnimatedImage({
  src,
  baseAngle,
  phase,
  randomPos,
  progress,
  index,
  width,
  height,
  onScatterComplete,
}: AnimatedImageProps) {
  const { isMobile } = useTailwindBreakpoint();
  const isSafari =
    typeof navigator !== "undefined" &&
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  // Check if it's a mobile device and the browser is Safari
  const isMobileSafari =
    isMobile && isSafari && /Mobile/.test(navigator.userAgent);

  // console.log(isMobileSafari);

  const layout = isMobileSafari
    ? SAFARI_LAYOUT[index]
    : isMobile
      ? MOBILE_LAYOUT[index]
      : { width, height, x: randomPos.x, y: randomPos.y };

  const initialRadius = 200;
  const fullRotation = 2 * Math.PI;

  const x = useTransform(
    progress,
    (p: number) =>
      initialRadius * (1 - p) * Math.cos(baseAngle + p * fullRotation)
  );
  const y = useTransform(
    progress,
    (p: number) =>
      initialRadius * (1 - p) * Math.sin(baseAngle + p * fullRotation)
  );

  if (phase === "group") {
    return (
      <motion.img
        key={`group-${index}`}
        src={src}
        alt="animated"
        style={{
          position: "absolute",
          width: width,
          height: height,
          scale: 0.1,
          x,
          y,
        }}
      />
    );
  } else {
    return (
      <motion.img
        key={`scatter-${index}`}
        src={src}
        alt={`animated-${index}`}
        className="absolute object-contain"
        style={{
          width: layout.width,
          height: layout.height,
        }}
        initial={{ x: 0, y: 0, scale: 0.1 }}
        animate={{
          x: layout.x,
          y: layout.y,
          scale: isMobile ? 1 : 1.2,
        }}
        transition={{
          type: "spring",
          stiffness: 170,
          damping: 15,
          bounce: 0.35,
          duration: 1.2,
        }}
        onAnimationComplete={() => {
          if (onScatterComplete) onScatterComplete();
        }}
      />
    );
  }
}

function Animation({ setCompleted }: Props) {
  const [phase, setPhase] = useState<"group" | "scatter">("group");
  const [completedCount, setCompletedCount] = useState(0);
  const progress = useMotionValue(0);

  useEffect(() => {
    const controls = animate(progress, 1, { duration: 0.8, ease: "easeInOut" });
    return controls.stop;
  }, [progress]);

  // Only use random positions for non-mobile devices
  const [randomPositions, setRandomPositions] = useState<
    { x: number; y: number }[]
  >([]);

  useEffect(() => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const margin = 50; // keep images away from the very edge

    const minRadius = 400;

    const halfMinDimension = Math.min(windowWidth, windowHeight) / 2;
    const computedMaxRadius = halfMinDimension - margin;
    const maxRadius =
      computedMaxRadius > minRadius + 50 ? computedMaxRadius : minRadius + 50;
    const totalImages = BACKGROUND_CARDS.length;
    const positions = BACKGROUND_CARDS.map((_, i) => {
      // Evenly spaced angle around the circle
      const angle = ((2 * Math.PI) / totalImages) * i;
      // Random radius between [minRadius, maxRadius]
      const radius = Math.random() * (maxRadius - minRadius) + minRadius;
      // Convert polar → Cartesian
      const x = radius * Math.cos(angle);
      const y = radius * Math.sin(angle);
      return { x, y };
    });
    setRandomPositions(positions);
  }, []);

  useEffect(() => {
    const scatterTimer = setTimeout(() => {
      setPhase("scatter");
    }, 1000);
    return () => clearTimeout(scatterTimer);
  }, []);

  useEffect(() => {
    if (completedCount === BACKGROUND_CARDS.length) {
      setCompleted(true);
    }
  }, [completedCount, setCompleted]);

  const handleScatterComplete = () => {
    setCompletedCount(prev => prev + 1);
  };

  return (
    <div className="relative flex h-screen w-full items-center justify-center overflow-hidden">
      {BACKGROUND_CARDS.map((card, i) => (
        <AnimatedImage
          key={`${i}-${phase}`}
          src={card.image.src}
          baseAngle={((2 * Math.PI) / BACKGROUND_CARDS.length) * i}
          phase={phase}
          width={card.image.width}
          height={card.image.height}
          randomPos={randomPositions[i]}
          progress={progress}
          index={i}
          onScatterComplete={
            phase === "scatter" ? handleScatterComplete : undefined
          }
        />
      ))}
    </div>
  );
}

export default memo(Animation);
